import { Box, SimpleGrid, Flex } from '@chakra-ui/react';
import ArtistCardSkeleton from '../ArtistCardSkeleton/ArtistCardSkeleton';
import BasicArtistCardSkeleton from '../BasicArtistCardSkeleton/BasicArtistCardSkeleton';

export interface ArtistGridSkeletonProps {
  rows?: number;
  gridDisplay?: boolean;
  gridMaxHeight?: number;
}

export default function ArtistGridSkeleton({ rows = 2, gridDisplay = true, gridMaxHeight = 600 }: ArtistGridSkeletonProps) {
  const skeletonsCount = 4 * rows;

  function renderGridSkeleton() {
    if (gridDisplay) {
      return (
        <Box backgroundColor="white" maxHeight={`${gridMaxHeight}px`} overflow="scroll" borderRadius="16px">
          <Flex direction='column'>
            {Array.from({ length: skeletonsCount }).map((_, index) => (
              <BasicArtistCardSkeleton key={`artist_card_skeleton_${index}`} />
            ))}
          </Flex>
        </Box>
      );
    } else {
      return (
        <SimpleGrid columns={[2, 4, 4]} spacing={{ base: "0px", md: "20px" }}>
          {Array.from({ length: skeletonsCount }).map((_, index) => (
            <ArtistCardSkeleton key={`artist_card_skeleton_${index}`} />
          ))}
        </SimpleGrid>
      );
    }
  }

  return (
    <Box>
      {renderGridSkeleton()}
    </Box>
  );
}
