import { useState, useEffect } from 'react';
import {
	Box,
	SimpleGrid,
	Flex,
	Stack,
	Heading,
	Icon,
	Image,
	Tooltip,
	Spacer,
	Spinner,
	StackDivider
} from '@chakra-ui/react';
import {
  BiDollar
} from 'react-icons/bi';
import {
  BsFolder,
  BsFolderFill
} from 'react-icons/bs';
import {
  AiFillHeart,
  AiOutlineHeart,
	AiOutlineLineChart
} from 'react-icons/ai';
import {
  VscEdit
} from 'react-icons/vsc';
import { IoFlagOutline } from "react-icons/io5";
import { ArtistModel, ItemModel } from 'models';
import { Feature, BasicArtistCard, GrowthBadge, ActionButton } from 'components';

export interface ItemContainerProps {
	item: ItemModel;
	artist: ArtistModel | null | undefined;
	isFollowingArtist: boolean;
	showMarketButtons: boolean;
	portfolioButtonLoading: boolean;
	wishlistButtonLoading: boolean;
	followArtistLoading: boolean;
	portfolioContainsItem: boolean;
	wishlistContainsItem: boolean;
	onPortfolioClick: () => void;
	onWishlistClick: () => void;
	onEditClick: () => void;
	onFlagClick: () => void;
	onFollowArtistClick: () => void;
	onArtistClick: () => void;
}

export enum ItemContainerSizingConfig {
	IMAGE_MAX_HEIGHT = '500px',
	SECTION_SPACING = '20px',
}

export default function ItemContainer({
	item,
	artist,
	isFollowingArtist,
	showMarketButtons,
	portfolioButtonLoading,
	wishlistButtonLoading,
	followArtistLoading,
	portfolioContainsItem,
	wishlistContainsItem,
	onPortfolioClick,
	onWishlistClick,
	onEditClick,
	onFlagClick,
	onFollowArtistClick,
	onArtistClick
}: ItemContainerProps) {
	const [artistData, setArtistData] = useState<ArtistModel | null | undefined>(artist);

	useEffect(() => {
		if (artist) {
			setArtistData(artist);
		}
	}, [artist]);

	const getClosePriceColor = (): string => {
		let iconColor = 'gray';
		if (item.hasMarketData()) {
			if (item.getEstimatedMarketPrice() > item.getClosePrice()) {
				iconColor = 'red';
			} else if (item.getEstimatedMarketPrice() < item.getClosePrice()) {
				iconColor = 'green';
			}
		}
		return iconColor;
	}

	function renderContainerUserButton(icon: React.ElementType, onClick: () => void, isLoading: boolean) {
		return (
			<Flex
				w={8}
				h={8}
				align={'center'}
				justify={'center'}
				rounded={'full'}
				cursor={'pointer'}
				border="1px"
				onClick={onClick}
				_hover={{ color: 'blue.500' }}
			>
				{isLoading ? <Spinner size="sm" /> : <Icon as={icon} />}
			</Flex>
		);
	}

	function renderContainerUserButtons() {
		const portfolioTooltipLabel = portfolioContainsItem ? 'Remove from Collection' : 'Add to Collection';
		const wishlistTooltipLabel = wishlistContainsItem ? 'Unfavorite' : 'Favorite';
		const portfolioIconType = portfolioContainsItem ? BsFolderFill : BsFolder;
		const wishlistIconType = wishlistContainsItem ? AiFillHeart : AiOutlineHeart;

		return (
			<Flex direction="row" justifyContent="center">
				<Flex width="40%">
					<Tooltip label={portfolioTooltipLabel}>
						{renderContainerUserButton(portfolioIconType, onPortfolioClick, portfolioButtonLoading)}
					</Tooltip>
					<Spacer />
					<Tooltip label={wishlistTooltipLabel}>
						{renderContainerUserButton(wishlistIconType, onWishlistClick, wishlistButtonLoading)}
					</Tooltip>
					<Spacer />
					<Tooltip label="Suggest an Edit">
						{renderContainerUserButton(VscEdit, onEditClick, false)}
					</Tooltip>
					<Spacer />
					<Tooltip label="Flag this item">
						{renderContainerUserButton(IoFlagOutline, onFlagClick, false)}
					</Tooltip>
				</Flex>
			</Flex>
		);
	}

	function renderArtistSection() {
		if (artistData) {
			const buttonText = isFollowingArtist ? 'Unfollow' : 'Follow';
			const buttonIcon = isFollowingArtist ? <AiFillHeart /> : <AiOutlineHeart />;
			
			return (
				<BasicArtistCard
					artist={artistData}
					avatarSize="md"
					titleSize="lg"
					showAdditionalInfo
					showButton
					onArtistTextClick={onArtistClick}
					buttonText={buttonText}
					buttonIcon={buttonIcon}
					buttonLoading={followArtistLoading}
					onButtonClick={onFollowArtistClick}
					showBorder={false}
				/>
			);
		}
	}

	function renderContainerFeatures() {
		const lastSaleIconColor = getClosePriceColor();
		const containerDivider = <StackDivider borderColor={'gray.100'} />;
		let secondaryMarketPremiumText = 'No Market Data';
    if (item.hasMarketData() && item.hasReleasePriceData()) {
      secondaryMarketPremiumText = item.getSecondaryMarketPremiumForDisplay();
    } else if (item.hasMarketData() && !item.hasReleasePriceData()) {
      secondaryMarketPremiumText = 'Retail Price Unknown';
    }
		return (
			<Stack paddingTop={ItemContainerSizingConfig.SECTION_SPACING} spacing={4} divider={containerDivider}>
				<Feature
					icon={<Icon as={BiDollar} color={'purple.500'} w={5} h={5} />}
					iconBg={'purple.100'}
					text={item.hasMarketData() ? item.getEstimatedMarketPriceForDisplay() : 'No Market Data'}
					subText={item.hasMarketData() ? 'Est. market price' : ''}
				/>
				<Feature
					icon={<Icon as={BiDollar} color={`${lastSaleIconColor}.500`} w={5} h={5} />}
					iconBg={`${lastSaleIconColor}.100`}
					text={item.hasMarketData() ? (
						<Flex alignItems={'center'} gap={2}>
							{item.getClosePriceForDisplay()}
							{item.hasMultipleSales() && <GrowthBadge percentage={item.getLastSalePercentageChange()} />}
						</Flex>
					) : 'No Recorded Sales'}
					subText={item.hasMarketData() ? 'Last Sale' : ''}
				/>
				<Feature
          icon={
            <Icon as={AiOutlineLineChart} color={'yellow.500'} w={5} h={5} />
          }
          iconBg={'yellow.100'}
          text={secondaryMarketPremiumText}
          subText={item.hasMarketData() ? 'Secondary Market Premium' : ''}
        />
			</Stack>
		);
	}

	function renderMarketButtons() {
		if (showMarketButtons) {
			return (
				<Box paddingTop={ItemContainerSizingConfig.SECTION_SPACING}>
					<Flex>
						<ActionButton text="Bids" />
						<Spacer />
						<ActionButton text="Asks" />
					</Flex>
				</Box>
			);
		}
	}

	function renderItemImage() {
		return (
			<Flex justifyContent="center">
				<Image
					rounded="md"
					alt={item.name}
					src={item.image}
					objectFit="contain"
					maxHeight={ItemContainerSizingConfig.IMAGE_MAX_HEIGHT}
				/>
			</Flex>
		);
	}

	return (
		<SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
			{renderItemImage()}
			<Stack spacing={4}>
				<Heading>{item.name}</Heading>
				{renderContainerUserButtons()}
				{renderArtistSection()}
				{renderContainerFeatures()}
				{renderMarketButtons()}
			</Stack>
		</SimpleGrid>
	);
}
