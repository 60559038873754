import {
	Box,
	Skeleton,
	SkeletonText,
	Flex,
	Tag
} from '@chakra-ui/react';

export enum ListingAccordionSizingConfig {
	CARD_BORDER_WIDTH = '2px',
	IMAGE_WIDTH = '80px',
	IMAGE_HEIGHT = '80px',
}

export default function ListingAccordionSkeleton() {

	function renderSkeletonBox() {
		return (
			<Flex direction="row" justifyContent="center" alignItems="center">
				<Skeleton height={ListingAccordionSizingConfig.IMAGE_HEIGHT} width={ListingAccordionSizingConfig.IMAGE_WIDTH} />

				<Flex width="100%" direction={{ base: 'column', md: 'row' }} marginLeft="20px">
					<Box p={{ base: 1, md: 6 }} width={{ base: '100%', md: '40%' }} textAlign={'left'}>
						<SkeletonText noOfLines={1} width="80%" />
						<SkeletonText noOfLines={1} width="50%" mt={2} />
					</Box>

					<Box p={{ base: 1, md: 6 }} width={{ base: '100%', md: '30%' }} textAlign={{ base: 'left', md: 'center' }}>
						<SkeletonText noOfLines={1} width="60%" />
						<SkeletonText noOfLines={1} width="40%" mt={2} />
					</Box>

					<Box p={{ base: 1, md: 6 }} width={{ base: '100%', md: '30%' }} textAlign={{ base: 'left', md: 'right' }}>
						<Flex direction={'row'} wrap={'wrap'} gap={1} alignItems={'center'} justifyContent={{ base: 'flex-start', md: 'flex-end' }} mb={2}>
							<Skeleton height="20px" width="60px" />
						</Flex>
						<Flex direction='row' gap={1} alignItems={'center'} justifyContent={{ base: 'flex-start', md: 'flex-end' }}>
							<Skeleton height="15px" width="40px" />
						</Flex>
					</Box>
				</Flex>
			</Flex>
		);
	}

	return (
		<Box
			paddingLeft={4}
			paddingTop={4}
			paddingBottom={4}
			paddingRight={8}
			border={ListingAccordionSizingConfig.CARD_BORDER_WIDTH}
			borderColor={'gray.200'}
			width={"100%"}
		>
			{renderSkeletonBox()}
		</Box>
	);
}
