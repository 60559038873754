import {
	Stack,
	Heading,
	Checkbox,
	HStack,
	Text,
	Button
} from '@chakra-ui/react';
import { ListingModel } from 'models';
import DateSlider from '../../DateSlider/DateSlider';
import NumberSlider from '../../NumberSlider/NumberSlider';
import { roundUpToSignificant } from 'utils/number';
import { FcClearFilters } from "react-icons/fc";

export interface ListingGridFiltersProps {
	onListingSaleDateRangeSelect?: (minYear: number, maxYear: number) => void;
	onListingPriceRangeSelect?: (minValue: number, maxValue: number) => void;
	onListingShowOriginalToggle?: () => void;
	onListingShowSetToggle?: () => void;
	listings: ListingModel[];
	showTypeFilter?: boolean;
	defaultSaleDateRange?: number[];
	defaultPriceRange?: number[];
	defaultShowOriginal?: boolean;
	defaultShowSet?: boolean;
	showClearOption?: boolean;
	onResetFilters?: () => void;
}

export default function ListingGridFilters({
	listings = [],
	onListingSaleDateRangeSelect = () => { },
	onListingPriceRangeSelect = () => { },
	onListingShowOriginalToggle = () => { },
	onListingShowSetToggle = () => { },
	showTypeFilter = false,
	defaultSaleDateRange,
	defaultPriceRange,
	defaultShowOriginal,
	defaultShowSet,
	showClearOption,
	onResetFilters
}: ListingGridFiltersProps) {
	const getDateRangeMinValue = () => {
		let minListingYear = 2000;
		const listingSaleDateYears: number[] = listings.map(listing => listing.getDateYear()).filter(saleDateYear => saleDateYear != Infinity && saleDateYear != 0);
		if (listingSaleDateYears.length > 0) {
			minListingYear = Math.min(...listingSaleDateYears);
		}
		return minListingYear;
	}

	const getMaxListingPrice = () => {
		let maxPrice = 50000;
		const prices = listings.map(listing => listing.rawPrice);
		if (prices.length > 0) {
			maxPrice = roundUpToSignificant(Math.max(...prices));
		}

		if (maxPrice > 100000) {
			maxPrice = 100000;
		}

		return maxPrice;
	}

	const calculateStepSize = (maxPrice: number) => {
		return maxPrice / 10;
	}

	function renderFilterLabel(label: string) {
		return (
			<Heading size={'xs'} textAlign={'left'}>{label}</Heading>
		);
	}

	function renderTypeFilters() {
		if (showTypeFilter) {
			return (
				<Stack>
					{renderFilterLabel('Types')}
					<HStack gap={4} padding={2}>
						<Checkbox isChecked={defaultShowOriginal} onChange={() => onListingShowOriginalToggle()}>
							<Text fontSize={'sm'}>Original</Text>
						</Checkbox>
						<Checkbox isChecked={defaultShowSet} onChange={() => onListingShowSetToggle()}>
							<Text fontSize={'sm'}>Set</Text>
						</Checkbox>
					</HStack>
				</Stack>
			);
		}
	}

	function renderSalePriceRangeSlider() {
		const listingMaxPrice = getMaxListingPrice();
		return (
			<Stack>
				{renderFilterLabel('Sale Price')}
				<NumberSlider
					dropdownDisplay={false}
					rangeMax={getMaxListingPrice()}
					startingMax={defaultPriceRange?.[1] || getMaxListingPrice()}
					startingMin={defaultPriceRange?.[0]}
					step={calculateStepSize(listingMaxPrice)}
					onNumberRangeSelect={onListingPriceRangeSelect} />
			</Stack>
		);
	}

	function renderSaleDateRangeSlider() {
		return (
			<Stack>
				{renderFilterLabel('Sale Year')}
				<DateSlider
					onYearRangeSelect={onListingSaleDateRangeSelect}
					rangeMin={getDateRangeMinValue()}
					startingMinDateYear={defaultSaleDateRange?.[0] || getDateRangeMinValue()}
					startingMaxDateYear={defaultSaleDateRange?.[1]}
					dropdownDisplay={false}
				/>
			</Stack>
		);
	}

	function renderResetButton() {
		if (showClearOption) {
			return (
				<Button size={'md'} leftIcon={<FcClearFilters />} variant='outline' maxWidth={'fit-content'} onClick={onResetFilters}>
					Reset Filters
				</Button>
			);
		}
	}

	return (
		<Stack marginBottom={10} gap={4} paddingInline={{ base: 3, md: 0 }}>
			{renderResetButton()}
			{renderTypeFilters()}
			{renderSaleDateRangeSlider()}
			{renderSalePriceRangeSlider()}
		</Stack>
	);
}
