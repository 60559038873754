import {
    Flex,
    SimpleGrid,
    SkeletonCircle,
    SkeletonText,
    useBreakpointValue
  } from '@chakra-ui/react';
  
  export interface ArtistAvatarGridSkeletonProps {
    artistsPerRow?: number;
    rows?: number;
    showArtistName?: boolean;
  }
  
  export default function ArtistAvatarGridSkeleton({ artistsPerRow = 4, rows = 2, showArtistName = true }: ArtistAvatarGridSkeletonProps) {
    const isMobile = useBreakpointValue({ base: true, md: false });
    const avatarSize = isMobile ? '16' : '24';
    const skeletonsCount = artistsPerRow * rows;
  
    function renderArtistSkeleton(index: number) {
      return (
        <Flex key={`artistAvatarGridSkeleton_${index}`} direction='column' alignItems='center' gap={4}>
          <SkeletonCircle size={avatarSize} />
          {showArtistName && <SkeletonText mt="4" noOfLines={1} width={"60px"} />}
        </Flex>
      );
    }
  
    return (
      <SimpleGrid columns={artistsPerRow} spacing={{ base: "10px", md: "20px" }}>
        {Array.from({ length: skeletonsCount }).map((_, index) => renderArtistSkeleton(index))}
      </SimpleGrid>
    );
  }
  