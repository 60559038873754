import {
	Box,
	Skeleton,
	SkeletonText,
	SkeletonCircle,
	Flex
} from '@chakra-ui/react';

export enum ArtistCardSizingConfig {
	CARD_BORDER_WIDTH = '1px',
	IMAGE_HEIGHT = '200px'
}

export interface ArtistCardSkeletonProps {
	useAvatar?: boolean;
}

export default function ArtistCardSkeleton({ useAvatar = false }: ArtistCardSkeletonProps) {

	function renderArtistAvatarSkeleton() {
		return (
			<Flex height={ArtistCardSizingConfig.IMAGE_HEIGHT}>
				<SkeletonCircle height={'full'} width={'full'} />
			</Flex>
		);
	}

	function renderArtistImageSkeleton() {
		if (useAvatar) {
			return renderArtistAvatarSkeleton();
		} else {
			return (
				<Skeleton height={ArtistCardSizingConfig.IMAGE_HEIGHT} width="100%" />
			);
		}
	}

	return (
		<Box
			maxW='sm'
			borderWidth={ArtistCardSizingConfig.CARD_BORDER_WIDTH}
			borderRadius='lg'
			overflow='hidden'
		>
			{renderArtistImageSkeleton()}
			<Box p='6'>
				<SkeletonText mt="4" noOfLines={1} spacing="4" />
			</Box>
		</Box>
	);
}
