import {
	Flex
} from '@chakra-ui/react';
import ListingAccordionSkeleton from '../ListingAccordionSkeleton/ListingAccordionSkeleton';

export interface ListingGridSkeletonProps {
	rows?: number;
}

export default function ListingGridSkeleton({ rows = 4 }: ListingGridSkeletonProps) {
	const skeletonsCount = rows;

	return (
		<Flex direction="column" gap="12px">
			{Array.from({ length: skeletonsCount }).map((_, index) => (
				<ListingAccordionSkeleton key={`listing_grid_skeleton_${index}`} />
			))}
		</Flex>
	);
}
