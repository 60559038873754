import {
	Box,
	Flex,
	Stack,
	StackDivider,
	Avatar,
	Heading,
	StatGroup,
	Stat,
	StatLabel,
	StatNumber,
	SkeletonText,
	Spinner
} from '@chakra-ui/react';
import { UserModel, WishlistModel } from 'models';
import { toDollarFormat } from 'utils/format';

export interface UserWishlistBadgeProps {
	user: UserModel;
	wishlist?: WishlistModel;
	dataLoading?: boolean;
	userLoading?: boolean;
}

export default function UserWishlistBadge({ user, wishlist, dataLoading, userLoading }: UserWishlistBadgeProps) {
	function renderWishlistInfo() {
		if (dataLoading) {
			return (
				<Spinner
					marginTop={8}
					thickness='4px'
					speed='0.65s'
					emptyColor='gray.200'
					color='blue.500'
					size='xl'
				/>
			);
		}

		const wishlistValue = (wishlist || user.wishlist).getEstimatedMarketValue();
		const containerDivider = <StackDivider borderColor={'gray.100'} />;

		return (
			<Stack
				paddingTop={6}
				spacing={4}
				alignItems={{ base: 'center', md: 'start' }}
				divider={containerDivider}>
				<StatGroup flexDirection={'row'} gap={8}>
					<Stat>
						<StatLabel>Total Value</StatLabel>
						<StatNumber>{toDollarFormat(wishlistValue)}</StatNumber>
					</Stat>
				</StatGroup>
			</Stack>
		);
	}

	function renderUserName() {
		const content = userLoading ? (
			<SkeletonText width={"200px"} noOfLines={1} skeletonHeight={"40px"} />
		) : (
			<Heading>
				@{user.userName}
			</Heading>
		);

		return (
			<Flex justifyContent={{ 'base': 'center', 'md': 'left' }}>
				{content}
			</Flex>
		);
	}

	return (
		<Box borderBottomWidth='1px' borderRadius='lg' paddingBottom="20px">
			<Flex direction={{ 'base': 'column', 'md': 'row' }}>
				<Stack alignItems={'center'}>
					<Avatar
						src={user.getImage()}
						me={{ md: "22px" }}
						w='200px'
						h='200px'
					/>
				</Stack>
				<Box textAlign={{ 'base': 'center', 'md': 'left' }} paddingLeft={{ 'base': '0px', 'md': '48px' }} paddingTop={{ 'base': '20px', 'md': '0px' }}>
					{renderUserName()}
					{renderWishlistInfo()}
				</Box>
			</Flex>
		</Box>
	);
}
