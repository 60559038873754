import {
	SimpleGrid,
	Flex,
	Skeleton,
	SkeletonText,
	Stack,
	SkeletonCircle,
	Spacer,
	StackDivider
} from '@chakra-ui/react';

export default function ItemContainerSkeleton() {
	function renderSkeletonButtons() {
		return (
			<Flex direction="row" justifyContent="center">
				<Flex width="40%">
					<SkeletonCircle size="8" />
					<Spacer />
					<SkeletonCircle size="8" />
					<Spacer />
					<SkeletonCircle size="8" />
					<Spacer />
					<SkeletonCircle size="8" />
				</Flex>
			</Flex>
		);
	}

	function renderSkeletonArtistSection() {
		return (
			<Stack direction="row" align="center" gap={8}>
				<SkeletonCircle size="12" />
				<SkeletonText noOfLines={2} width="200px" />
			</Stack>
		);
	}

	function renderSkeletonFeatures() {
		const containerDivider = <StackDivider borderColor={'gray.100'} />;

		return (
			<Stack paddingTop="20px" spacing={4} divider={containerDivider}>
				<Flex gap={4}>
					<SkeletonCircle size="8" />
					<Stack gap={2}>
						<SkeletonText noOfLines={1} width="150px" />
						<SkeletonText noOfLines={1} width="100px" />
					</Stack>
				</Flex>
				<Flex gap={4}>
					<SkeletonCircle size="8" />
					<Stack gap={2}>
						<SkeletonText noOfLines={1} width="150px" />
						<SkeletonText noOfLines={1} width="100px" />
					</Stack>
				</Flex>
				<Flex gap={4}>
					<SkeletonCircle size="8" />
					<Stack gap={2}>
						<SkeletonText noOfLines={1} width="150px" />
						<SkeletonText noOfLines={1} width="100px" />
					</Stack>
				</Flex>
			</Stack>
		);
	}

	function renderSkeletonImage() {
		return (
			<Flex justifyContent="center">
				<Skeleton height="500px" width="100%" />
			</Flex>
		);
	}

	return (
		<SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} width="100%">
			{renderSkeletonImage()}
			<Stack spacing={4}>
				<Skeleton height="40px" width="300px" alignSelf={'center'} />
				{renderSkeletonButtons()}
				{renderSkeletonArtistSection()}
				{renderSkeletonFeatures()}
			</Stack>
		</SimpleGrid>
	);
}
