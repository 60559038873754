import {
	Box,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Image,
	Flex,
	Heading,
	Text,
	Icon,
	Collapse
} from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
	BsCalendar3,
	BsFillTrashFill
} from 'react-icons/bs';
import {
	FiEdit
} from 'react-icons/fi';
import { useState, useEffect, useRef, Fragment } from 'react';
import { DetailedPortfolioItemModel, ItemModel } from 'models';
import GrowthBadge from '../../GrowthBadge/GrowthBadge';
import PortfolioTableBodySkeleton from '../PortfolioTableBodySkeleton/PortfolioTableBodySkeleton';
import EmptyState from '../../EmptyState/EmptyState';
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill, BsDashCircleFill } from 'react-icons/bs';
import { toDollarFormat } from 'utils/format';

export interface PortfolioTableProps {
	items: DetailedPortfolioItemModel[];
	isLoading: boolean;
	onItemNameClick?: (item: ItemModel) => void;
	onItemArtistClick?: (artistId: string) => void;
	onItemEditClick?: (item: ItemModel) => void;
	onItemRemovalClick?: (item: ItemModel) => void;
	onBrowseClick?: () => void;
}

export enum PortfolioTableSortColumn {
	LAST_SALE = 'LAST_SALE',
	CHANGE_PERCENTAGE = 'CHANGE_PERCENTAGE',
	QUANTITY = 'QUANTITY',
	PRICE_PAID = 'PRICE_PAID',
	TOTAL_GAIN_DOLLAR = 'TOTAL_GAIN_DOLLAR',
	TOTAL_GAIN_PERCENT = 'TOTAL_GAIN_PERCENT',
	MARKET_VALUE = 'MARKET_VALUE',
}

export default function PortfolioTable({
	items,
	isLoading,
	onItemNameClick = () => { },
	onItemArtistClick = () => { },
	onItemEditClick = () => { },
	onItemRemovalClick = () => { },
	onBrowseClick = () => { }
}: PortfolioTableProps) {
	const [sortedItems, setSortedItems] = useState<DetailedPortfolioItemModel[]>([]);
	const [expandedRows, setExpandedRows] = useState<number[]>([]);
	const [sortColumn, setSortColumn] = useState<PortfolioTableSortColumn | null>(null);
	const [sortAscending, setSortAscending] = useState<boolean>(true);
	const [width, setWidth] = useState(window.innerWidth);
	const tableHeaderRowRef = useRef<HTMLTableRowElement>(null);


	useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		setSortedItems(items);
	}, [items]);

	const handleSort = (column: PortfolioTableSortColumn) => {
		const newSortAscending = sortColumn === column ? !sortAscending : true;
		setSortAscending(newSortAscending);
		setSortColumn(column);

		const sorted = [...sortedItems].sort((a, b) => {
			let aValue = 0,
				bValue = 0;
			switch (column) {
				case PortfolioTableSortColumn.LAST_SALE:
					aValue = a.item.getEstimatedMarketPrice();
					bValue = b.item.getEstimatedMarketPrice();
					break;
				case PortfolioTableSortColumn.CHANGE_PERCENTAGE:
					aValue = a.item.getLastSalePercentageChange()
					bValue = b.item.getLastSalePercentageChange()
					break;
				case PortfolioTableSortColumn.QUANTITY:
					aValue = a.getTotalPurchaseCount();
					bValue = b.getTotalPurchaseCount();
					break;
				case PortfolioTableSortColumn.PRICE_PAID:
					aValue = a.getMeanPurchasePrice();
					bValue = b.getMeanPurchasePrice();
					break;
				case PortfolioTableSortColumn.TOTAL_GAIN_DOLLAR:
					aValue = a.getTotalPurchaseCount() * (a.item.getEstimatedMarketPrice() - a.getMeanPurchasePrice());
					bValue = b.getTotalPurchaseCount() * (b.item.getEstimatedMarketPrice() - b.getMeanPurchasePrice());
					break;
				case PortfolioTableSortColumn.TOTAL_GAIN_PERCENT:
					aValue = ((a.item.getEstimatedMarketPrice() - a.getMeanPurchasePrice()) / a.getMeanPurchasePrice()) * 100;
					bValue = ((b.item.getEstimatedMarketPrice() - b.getMeanPurchasePrice()) / b.getMeanPurchasePrice()) * 100;
					break;
				case PortfolioTableSortColumn.MARKET_VALUE:
					aValue = a.item.getEstimatedMarketPrice();
					bValue = b.item.getEstimatedMarketPrice();
					break;
			}
			return newSortAscending ? aValue - bValue : bValue - aValue;
		});

		setSortedItems(sorted);
	};

	const toggleRowExpansion = (index: number) => {
		if (expandedRows.includes(index)) {
			setExpandedRows(expandedRows.filter(row => row !== index));
		} else {
			setExpandedRows([...expandedRows, index]);
		}
	};

	const renderSortIcon = (column: PortfolioTableSortColumn) => {
		if (sortColumn !== column) return null;
		return sortAscending ? <TriangleUpIcon /> : <TriangleDownIcon />;
	};

	const renderDeltaWithColor = (value: number) => {
		let color = 'gray';
		let icon = BsDashCircleFill;

		if (value > 0) {
			color = 'green.500';
			icon = BsFillArrowUpCircleFill;
		} else if (value < 0) {
			color = 'red.500';
			icon = BsFillArrowDownCircleFill;
		}

		return (
			<Flex alignItems="center" color={color}>
				<Icon as={icon} w={4} h={4} mr={2} />
				{toDollarFormat(value)}
			</Flex>
		);
	};

	const renderEditColumnData = (item: DetailedPortfolioItemModel) => {
		return (
			<Flex gap={2} justifyContent="flex-end">
				<Icon
					as={FiEdit}
					w={4}
					h={4}
					cursor="pointer"
					_hover={{ color: "blue.500" }}
					onClick={() => onItemEditClick(item.item)}
				/>
				<Icon
					as={BsFillTrashFill}
					w={4}
					h={4}
					cursor="pointer"
					_hover={{ color: "red.500" }}
					onClick={() => onItemRemovalClick(item.item)}
				/>
			</Flex>
		)
	}

	const renderPurchaseInfoRow = (item: DetailedPortfolioItemModel) => {
		const columnWidths = Array.from(tableHeaderRowRef.current?.children || []).map(child => child.clientWidth);

		return item.purchaseInfo.map((purchase, index) => (
			<Tr key={`purchase_${index}`} bg="gray.50">
				<Td paddingLeft={{ base: 4, md: 6 }} paddingY={4} width={columnWidths[0]} textAlign={'left'}>
					<Flex direction={'row'} alignItems={'center'} gap={2}>
						<Icon
							as={BsCalendar3}
							w={4}
							h={4}
						/>
						<Text fontSize="xs" fontWeight="bold">
							{purchase.date.format('MM/DD/YYYY')}
						</Text>
					</Flex>
				</Td>
				<Td fontSize="xs" width={columnWidths[1]}>{item.item.getClosePriceForDisplay()}</Td>
				<Td fontSize="xs" width={columnWidths[2]}>
					<GrowthBadge
						percentage={item.item.getLastSalePercentageChange()}
					/>
				</Td>
				<Td fontSize="xs" width={columnWidths[3]}>{purchase.quantity}</Td>
				<Td fontSize="xs" width={columnWidths[4]}>{toDollarFormat(purchase.price)}</Td>
				<Td fontSize="xs" width={columnWidths[5]}>
					{renderDeltaWithColor(purchase.quantity * (item.item.getEstimatedMarketPrice() - purchase.price))}
				</Td>
				<Td fontSize="xs" width={columnWidths[6]}>
					<GrowthBadge
						starting={purchase.price}
						ending={item.item.getEstimatedMarketPrice()}
					/>
				</Td>
				<Td fontSize="xs" fontWeight={'bold'} width={columnWidths[7]}>
					{toDollarFormat(purchase.quantity * item.item.getEstimatedMarketPrice())}
				</Td>
				<Td width={columnWidths[8]}>
					{renderEditColumnData(item)}
				</Td>
			</Tr>
		));
	};

	const renderItemInfo = (item: DetailedPortfolioItemModel) => {
		return (
			<Flex alignItems="center">
				<Box minWidth={{ base: "50px", md: "80px" }} maxWidth={{ base: "50px", md: "80px" }} height={{ base: "50px", md: "80px" }} alignItems={'center'} mr={4}>
					<Image width={'100%'} height={'100%'} src={item.item.image} alt={item.item.name} fit={'contain'} />
				</Box>
				<Box>
					<Heading
						fontSize="xs"
						cursor="pointer"
						_hover={{ color: "blue.500" }}
						onClick={() => onItemNameClick(item.item)}
					>
						{item.item.name}
					</Heading>
					<Text
						fontSize="xs"
						cursor="pointer"
						_hover={{ color: "blue.500" }}
						onClick={() => onItemArtistClick(item.item.artistId)}
					>
						{item.item.getArtistName()}
					</Text>
				</Box>
			</Flex>
		)
	}

	function renderTableHeader() {
		return (
			<Thead>
				<Tr ref={tableHeaderRowRef}>
					<Th>Item</Th>
					<Th cursor="pointer" onClick={() => handleSort(PortfolioTableSortColumn.LAST_SALE)} alignItems="center">
						Last Sale {renderSortIcon(PortfolioTableSortColumn.LAST_SALE)}
					</Th>
					<Th cursor="pointer" onClick={() => handleSort(PortfolioTableSortColumn.CHANGE_PERCENTAGE)}>
						Change % {renderSortIcon(PortfolioTableSortColumn.CHANGE_PERCENTAGE)}
					</Th>
					<Th cursor="pointer" onClick={() => handleSort(PortfolioTableSortColumn.QUANTITY)}>
						Qty {renderSortIcon(PortfolioTableSortColumn.QUANTITY)}
					</Th>
					<Th cursor="pointer" onClick={() => handleSort(PortfolioTableSortColumn.PRICE_PAID)}>
						Price Paid $ {renderSortIcon(PortfolioTableSortColumn.PRICE_PAID)}
					</Th>
					<Th cursor="pointer" onClick={() => handleSort(PortfolioTableSortColumn.TOTAL_GAIN_DOLLAR)}>
						Gain $ {renderSortIcon(PortfolioTableSortColumn.TOTAL_GAIN_DOLLAR)}
					</Th>
					<Th cursor="pointer" onClick={() => handleSort(PortfolioTableSortColumn.TOTAL_GAIN_PERCENT)}>
						Gain % {renderSortIcon(PortfolioTableSortColumn.TOTAL_GAIN_PERCENT)}
					</Th>
					<Th cursor="pointer" onClick={() => handleSort(PortfolioTableSortColumn.MARKET_VALUE)}>
						Value {renderSortIcon(PortfolioTableSortColumn.MARKET_VALUE)}
					</Th>
					<Th textAlign={'right'}>Actions</Th>
				</Tr>
			</Thead>
		)
	}

	function renderTableBody() {
		if (isLoading) {
			return <PortfolioTableBodySkeleton rows={10} />
		} else {
			return (
				<Tbody>
					{sortedItems.map((item, index) => (
						<Fragment>
							<Tr key={index} onClick={() => toggleRowExpansion(index)} cursor="pointer">
								<Td padding={4} minW="200px">
									{renderItemInfo(item)}
								</Td>
								<Td fontSize="xs">{item.item.getClosePriceForDisplay()}</Td>
								<Td fontSize="xs">
									<GrowthBadge
										percentage={item.item.getLastSalePercentageChange()}
									/>
								</Td>
								<Td fontSize="xs">{item.getTotalPurchaseCount()}</Td>
								<Td fontSize="xs">{item.getMeanPurchasePriceForDisplay()}</Td>
								<Td fontSize="xs">
									{renderDeltaWithColor(item.getTotalPurchaseCount() * (item.item.getEstimatedMarketPrice() - item.getMeanPurchasePrice()))}
								</Td>
								<Td fontSize="xs">
									<GrowthBadge
										starting={item.getMeanPurchasePrice()}
										ending={item.item.getEstimatedMarketPrice()}
									/>
								</Td>
								<Td fontSize="xs" fontWeight={'bold'}>
									{toDollarFormat(item.getTotalPurchaseCount() * item.item.getEstimatedMarketPrice())}
								</Td>
								<Td>
									{renderEditColumnData(item)}
								</Td>
							</Tr>
							<Tr>
								<Td colSpan={9} p={0} borderBottom="none">
									<Collapse in={expandedRows.includes(index)} animateOpacity>
										<Box bg="gray.50">
											<Table variant="simple" size={'sm'}>
												<Tbody>
													{renderPurchaseInfoRow(item)}
												</Tbody>
											</Table>
										</Box>
									</Collapse>
								</Td>
							</Tr>
						</Fragment>
					))}
				</Tbody>
			);
		}
	}


	function renderContent() {
		if (!isLoading && items.length === 0) {
			return (
				<EmptyState header="No Items" description="No Items in Portfolio" buttonText="Browse Items" showButton={true} onButtonClick={onBrowseClick} />
			);
		} else {
			return (
				<Table variant="simple" size={'sm'}>
					{renderTableHeader()}
					{renderTableBody()}
				</Table>
			);
		}
	}

	return (
		<Box width={{ base: `${width - 10}px`, md: '100%' }} maxH={'600px'} overflowX="auto" overflowY="auto">
			{renderContent()}
		</Box>
	);
}
