import {
	Stack,
	Flex,
	Tbody,
	Tr,
	Td,
	Skeleton,
	SkeletonText
} from '@chakra-ui/react';

export interface PortfolioTableBodySkeletonProps {
	rows?: number;
}

export default function PortfolioTableBodySkeleton({ rows = 10 }: PortfolioTableBodySkeletonProps) {
	const columns = 9;

	function renderSkeletonRow(key: number) {
		return (
			<Tr key={key}>
				<Td padding={4}>
					<Flex alignItems="center">
						<Skeleton height="50px" width="50px" mr={4} />
						<Stack gap={1}>
							<SkeletonText noOfLines={1} width="100px" />
							<SkeletonText noOfLines={1} width="80px" />
						</Stack>
					</Flex>
				</Td>
				{Array.from({ length: columns - 1 }).map((_, index) => (
					<Td key={`skeleton_cell_${index}`}>
						<SkeletonText noOfLines={1} />
					</Td>
				))}
			</Tr>
		);
	}

	return (
		<Tbody>
			{Array.from({ length: rows }).map((_, index) => renderSkeletonRow(index))}
		</Tbody>
	);
}
