import {
  Box,
  Flex,
  Skeleton,
  SkeletonText,
  Spacer,
  Stack
} from '@chakra-ui/react';

export interface ItemCardSkeletonProps {
  size?: 'sm' | 'md';
}

export enum ItemCardSizingConfig {
  CARD_BORDER_WIDTH = '1px',
  MD_IMAGE_HEIGHT = '200px',
  SMALL_IMAGE_HEIGHT = '140px'
}

export default function ItemCardSkeleton({ size = 'sm' }: ItemCardSkeletonProps) {
  const imageHeight = size === 'md' ? ItemCardSizingConfig.MD_IMAGE_HEIGHT : ItemCardSizingConfig.SMALL_IMAGE_HEIGHT;

  return (
    <Flex
      p='6'
      direction="column"
      borderWidth={ItemCardSizingConfig.CARD_BORDER_WIDTH}
      borderRadius='lg'
      height={'100%'}
      minHeight={'360px'}
      gap={2}
      alignItems={'center'}
    >
      <Skeleton height={imageHeight} width={'100%'} />

      <Box mt='2' lineHeight='tight'>
        <Skeleton height="20px" width="80%" />
      </Box>

      <Spacer />

      <Stack width={'100%'} gap={2}>
        <Flex direction="row" alignItems="center" width={'100%'}>
          <SkeletonText noOfLines={1} width="60%" />
          <Spacer />
          <Skeleton height="20px" width="20px" />
        </Flex>

        <SkeletonText noOfLines={1} width="100%" />
      </Stack>
    </Flex>
  );
}
