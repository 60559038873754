import { JSONObject } from "models/Api/types";
import { ItemModel, ListingModel } from "models";
import { ItemSearchFiltersFormData, ItemSortFilter } from 'components/Form/ItemSearchFiltersForm/ItemSearchFiltersForm';
import { ListingSearchFiltersFormData, ListingSortFilter } from 'components/Form/ListingSearchFiltersForm/ListingSearchFiltersForm';
import { convertJSONObjectToSnakecase, flattenKeys } from './object';

export function convertSearchFilterDataToQuery(
    searchQuery: ItemSearchFiltersFormData
): JSONObject {
    const queryParams: JSONObject = {};
    Object.entries(searchQuery).forEach((data) => {
      queryParams[data[0]] = data[1];
    });
    const formattedQueryParams: JSONObject = convertJSONObjectToSnakecase(queryParams);
    if ('gt' in queryParams) {
      formattedQueryParams['gt'] = flattenKeys(formattedQueryParams['gt']);
    }

    if('lt' in queryParams) {
      formattedQueryParams['lt'] = flattenKeys(formattedQueryParams['lt']);
    }
    return formattedQueryParams;
}


export function convertQueryToSearchFilterData(
    searchQuery: JSONObject
): ItemSearchFiltersFormData {
    const searchFilterData: ItemSearchFiltersFormData = {};
    Object.entries(searchQuery).forEach(([key, value]) => {
			if (typeof value == 'string') {
				if (key === 'query') {
					searchFilterData.query = value;
				}
				if (key === 'type') {
					searchFilterData.type = [value];
				}
				if (key === 'materialIds') {
					searchFilterData.materialIds = [value];
				}
				if (key === 'artistId') {
					searchFilterData.artistId = [value];
				}
        if (key === 'sortBy') {
          searchFilterData.sortBy = value as ItemSortFilter;
        }
        if (key === 'sortType') {
          if (value.includes('asc')) {
            searchFilterData.sortType = 'asc';
          } else {
            searchFilterData.sortType = 'desc';
          }
        }
			}
    });
    return searchFilterData;
}


export function convertListingSearchFilterDataToQuery(
  searchQuery: ListingSearchFiltersFormData,
  showAssociatedItems?: boolean
): JSONObject {
  const queryParams: JSONObject = {};
  Object.entries(searchQuery).forEach((data) => {
    if (Array.isArray(data[1]) && data[1].length == 0) {
      return;
    }
    queryParams[data[0]] = data[1];
  });
  const formattedQueryParams: JSONObject = convertJSONObjectToSnakecase(queryParams);
  if ('gt' in queryParams) {
    formattedQueryParams['gt'] = flattenKeys(formattedQueryParams['gt']);
  }

  if('lt' in queryParams) {
    formattedQueryParams['lt'] = flattenKeys(formattedQueryParams['lt']);
  }

  if (showAssociatedItems) {
    formattedQueryParams['associate_items'] = true;
  }

  return formattedQueryParams;
}


export function convertListingQueryToSearchFilterData(
  searchQuery: JSONObject
): ListingSearchFiltersFormData {
  const searchFilterData: ListingSearchFiltersFormData = {};
  Object.entries(searchQuery).forEach(([key, value]) => {
    if (typeof value == 'string') {
      if (key === 'query') {
        searchFilterData.query = value;
      }
      if (key === 'type') {
        searchFilterData.type = [value];
      }
      if (key === 'artistId') {
        searchFilterData.artistId = [value];
      }
      if (key === 'isUnique') {
        searchFilterData.isUnique = !!value;
      }
      if (key === 'multipleListings') {
        searchFilterData.multipleItems = !!value;
      }
      if (key === 'sortBy') {
        searchFilterData.sortBy = value as ListingSortFilter;
      }
      if (key === 'sortType') {
        if (value.includes('asc')) {
          searchFilterData.sortType = 'asc';
        } else {
          searchFilterData.sortType = 'desc';
        }
      }
    }
  });
  return searchFilterData;
}


export function filterItemsByQuery(items: ItemModel[], query: string): ItemModel[] {
  const queryWords = query.toLowerCase().split(/\s+/);

  const textQueries = queryWords.filter(word => !/^\d+$/.test(word));
  const numberQueries = queryWords.filter(word => /^\d+$/.test(word)).map(Number);

  return items.filter(item => {
      const itemData = item.name.toLowerCase() + ' ' + item.getArtistName().toLowerCase() + item.material.toLowerCase();

      const isTextMatch = textQueries.length === 0 || textQueries.every(word => itemData.includes(word));

      const isNumberMatch = numberQueries.length === 0 || numberQueries.some(number => 
        (!!item.getReleaseYear() && (item.getReleaseYear() === number || String(item.getReleaseYear()).includes(String(number)))) || (item.name.includes(String(number)))
      );

      return isTextMatch && isNumberMatch;
  });
}

export function filterListingsByQuery(listings: ListingModel[], query: string): ListingModel[] {
  const queryWords = query.toLowerCase().split(/\s+/);

  return listings.filter(listing => {
      const listingData = listing.name.toLowerCase() + ' ' + listing.artist.toLowerCase() + ' ' + listing.source.toLowerCase();
      return queryWords.every(word => listingData.includes(word));
  });
}
