import {
    Box,
    Skeleton,
    SkeletonText,
    Flex,
    Spacer,
    Stack,
    SkeletonCircle,
    Button,
  } from '@chakra-ui/react';
  
  export default function ArtistBannerSkeleton() {
    function renderSkeletonButton() {
      return (
        <Skeleton marginTop={{'base': '20px', 'md': '0px'}}>
          <Button maxWidth="33%">
            Follow
          </Button>
        </Skeleton>
      );
    }
  
    function renderSkeletonContactIcons() {
      return (
        <Flex direction="column" gap="16px" mt={8} mb={8}>
          <Flex alignItems="center" gap="8px">
            <SkeletonCircle size="10" />
            <SkeletonText noOfLines={1} width="100px" />
          </Flex>
          <Flex alignItems="center" gap="8px">
            <SkeletonCircle size="10" />
            <SkeletonText noOfLines={1} width="100px" />
          </Flex>
          <Flex alignItems="center" gap="8px">
            <SkeletonCircle size="10" />
            <SkeletonText noOfLines={1} width="100px" />
          </Flex>
        </Flex>
      );
    }
  
    return (
      <Box borderBottomWidth="1px" borderRadius="lg" paddingBottom="20px" width="100%">
        <Flex direction={{ base: 'column', md: 'row' }}>
          {/* Skeleton for artist image */}
          <Skeleton
            height="480px"
            width={{ base: '100%', md: '50%' }}
            borderRadius={{ base: '0px', md: '16px' }}
          />
  
          {/* Skeleton for text and details */}
          <Box
            textAlign={{ base: 'center', md: 'left' }}
            paddingLeft={{ base: '0px', md: '48px' }}
            paddingTop={{ base: '20px', md: '0px' }}
            width="100%"
          >
            <Flex direction={{ base: 'column', md: 'row' }} alignItems="center">
              {/* Skeleton for artist name */}
              <SkeletonText noOfLines={1} width="200px" skeletonHeight="40px" />
              <Spacer />
              {renderSkeletonButton()}
            </Flex>
  
            {/* Skeleton for artist contact icons */}
            {renderSkeletonContactIcons()}
  
            {/* Skeleton for description */}
            <SkeletonText mt="20px" noOfLines={4} spacing="4" />
          </Box>
        </Flex>
      </Box>
    );
  }
  