import { RawItemData } from "models/Item/types";
import { MeasurementData } from '../types';


export interface RawListingData {
	id: string;
	artist: string;
	artist_id: string;
	source_id: string;
	date: string;
	image: string;
	items: (string | RawItemData)[];
	link: string;
	name: string;
	price: string;
	processing_status: ListingStatus;
	raw_price: number;
	source: string;
	status: string;
	type: ListingType;
	item: string | RawItemData;
	authentic: boolean;
	measurements: string;
	dimensions: MeasurementData;
	material: string;
	material_ids: string[];
	multiple_items: boolean;
	is_unique: boolean;
}

export enum ListingType {
	PRINT = 'Prints',
	SCULPTURE = 'Sculpture',
	MISC = 'Misc.',
	UNKNOWN = 'Unknown',
	SET = 'Set',
	ORIGINAL = 'Original'
}

export enum ListingStatus {
	PENDING = 'Pending',
	GROUPING_PENDING = 'Grouping Pending',
	APPROVED = 'Approved',
	REJECTED = 'Rejected'
}