import {
	Box,
	Image,
	Flex,
	Heading,
	Text,
	Tag,
	useColorModeValue
} from '@chakra-ui/react';
import { ListingModel } from 'models/Listing/Listing';

export interface BasicListingCardProps {
	listing: ListingModel;
	onClick?: () => void;
	showLabel?: boolean;
	overrideConfig?: {
		isUnique?: boolean;
		multipleItems?: boolean;
		fake?: boolean;
	}
}

export enum BasicListingCardSizingConfig {
	CARD_HEIGHT = '80px',
	PANEL_MIN_HEIGHT = '80px'
}

export enum BasicListingCardStylingConfig {
	BORDER_COLOR_LIGHT = '#f1f3f7',
	BORDER_COLOR_DARK = '#3f434c'
}

export default function BasicListingCard({ listing, onClick, showLabel = false, overrideConfig = {} }: BasicListingCardProps) {
	const hasOverrideData = overrideConfig.fake || overrideConfig.isUnique || overrideConfig.multipleItems;
	function renderTag(label: string, colorScheme: string) {
		return (
			<Tag size={'sm'} key={'sm'} variant='solid' colorScheme={colorScheme} marginTop={2}>
				{label}
			</Tag>
		)
	}

	function renderListingLabel() {
		if (listing.isOriginal()) {
			return renderTag('Original', 'pink');
		} else if (listing.isSet()) {
			return renderTag('Set', 'purple');
		} else if (!listing.isAuthentic()) {
			return renderTag('Fake', 'red');
		}
	}

	function renderOverrideLabel() {
		if (overrideConfig.isUnique) {
			return renderTag('Original', 'pink');
		} else if (overrideConfig.multipleItems) {
			return renderTag('Set', 'purple');
		} else if (overrideConfig.fake) {
			return renderTag('Fake', 'red');
		}
	}

	function renderLabel() {
		if (showLabel) {
			return hasOverrideData ? renderOverrideLabel() : renderListingLabel();
		}
	}

	return (
		<Box
			width="100%"
			padding="10px"
			border="1px solid"
			borderColor={useColorModeValue(BasicListingCardStylingConfig.BORDER_COLOR_LIGHT, BasicListingCardStylingConfig.BORDER_COLOR_DARK)}
			_hover={onClick ? { backgroundColor: useColorModeValue('gray.200', 'gray.800') } : {}}
			cursor={onClick ? 'pointer' : ''}
			onClick={onClick}
		>
			<Flex direction="row" alignItems="center">
				{listing.image && <Image loading="lazy" borderRadius="md" src={listing.image} alt={listing.name} width={BasicListingCardSizingConfig.CARD_HEIGHT} height={BasicListingCardSizingConfig.CARD_HEIGHT} marginRight={4} />}
				<Box>
					<Heading fontSize="sm" marginBottom={2}>{listing.name}</Heading>
					<Text fontSize="xs" color="gray.600">{listing.artist}</Text>
					<Text fontSize="xs" fontWeight="bold">{listing.getRawPriceForDisplay()}</Text>
					{renderLabel()}
				</Box>
			</Flex>
		</Box>
	);
}
