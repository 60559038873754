import {
	Flex,
	Skeleton,
	SkeletonText,
	Spacer,
	SkeletonCircle,
	useColorModeValue
} from '@chakra-ui/react';

export interface BasicArtistCardSkeletonProps {
	showButton?: boolean;
}

export enum BasicArtistCardSizingConfig {
	CARD_HEIGHT = '60px',
}

export enum BasicArtistCardStylingConfig {
	BORDER_COLOR_LIGHT = '#f1f3f7',
	BORDER_COLOR_DARK = '#3f434c'
}

export default function BasicArtistCardSkeleton({ showButton = false }: BasicArtistCardSkeletonProps) {
	return (
		<Flex
			direction='row'
			alignItems='center'
			gap='8px'
			minHeight={BasicArtistCardSizingConfig.CARD_HEIGHT}
			width="100%"
			overflow='hidden'
			padding='10px'
			border="1px solid"
			borderColor={useColorModeValue(BasicArtistCardStylingConfig.BORDER_COLOR_LIGHT, BasicArtistCardStylingConfig.BORDER_COLOR_DARK)}
		>
			<SkeletonCircle height="32px" width="32px" marginRight="16px" />
			<Flex direction="column" textAlign="left">
				<SkeletonText noOfLines={1} width="150px" skeletonHeight={"30px"} />
			</Flex>
			<Spacer />
			{showButton && (
				<Skeleton height="40px" width="100px" />
			)}
		</Flex>
	);
}
