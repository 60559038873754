import {
  Box,
  Image,
  Heading,
  Text,
  Flex,
  Button,
  Spinner,
  Spacer,
  Icon,
  Link,
  Tooltip,
  Stack,
  StackDivider
} from '@chakra-ui/react';
import CollapsableContent from '../../CollapsableContent/CollapsableContent';
import Feature from '../../Feature/Feature';
import ArtsyIcon from '../../CustomIcons/ArtsyIcon';
import { BsBell, BsBellFill, BsCalendar3 } from 'react-icons/bs';
import { FaInstagram } from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';
import { CgWebsite } from 'react-icons/cg';
import { RiGalleryLine } from 'react-icons/ri';
import { ArtistModel } from 'models/Artist/Artist';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';


export interface ArtistBannerProps {
  artist: ArtistModel;
  isFollowing?: boolean;
  followActionLoading?: boolean;
  onFollowClick?: () => void;
  onUnfollowClick?: () => void;
}

export enum ArtistBannerSizingConfig {
  CARD_BORDER_WIDTH = '1px',
  IMAGE_WIDTH = '302px',
  IMAGE_HEIGHT = '200px',
}

export default function ArtistBanner({ artist, isFollowing = false, followActionLoading = false, onFollowClick, onUnfollowClick }: ArtistBannerProps) {
  const onBannerButtonClick = (): void => {
    if (isFollowing && onUnfollowClick) {
      onUnfollowClick();
    }

    if (!isFollowing && onFollowClick) {
      onFollowClick();
    }
  }

  function renderArtistCountry() {
    const countryCode = artist.country.code;
    if (countryCode) {
      return (
        <Flex alignItems="center" gap="8px" justifyContent={{'base': 'center', 'md': 'left'}} paddingTop={{'base': '20px', 'md': '0px'}}>
          {getUnicodeFlagIcon(countryCode)}
          <Heading fontSize='sm'>
            {artist.country.name}
          </Heading>
        </Flex>
      );
    }
  }

  function renderBannerButton() {
    const buttonText = isFollowing ? 'Unfollow' : 'Follow';
    const buttonActionIcon = isFollowing ? <BsBellFill /> : <BsBell />;
    const buttonIcon = followActionLoading ? <Spinner size="sm" /> : buttonActionIcon;
    return (
      <Button leftIcon={buttonIcon} onClick={onBannerButtonClick} border="1px" maxWidth="33%" marginTop={{'base': '20px', 'md': '0px'}}>
        {buttonText}
      </Button>
    );
  }

  function renderArtistContactIcons() {
    const icons = [];
    if (artist.contactInfo.artsyLink) {
      icons.push(
        <Tooltip label={'Artsy'} key="artistBanner_artsy">
          <Link
            href={artist.contactInfo.artsyLink}
            isExternal={true}
            fontSize='lg'
            _hover={{ color: "blue.500" }}>
            <Icon as={ArtsyIcon} />
          </Link>
        </Tooltip>
      );
    }

    if (artist.contactInfo.instagramLink) {
      icons.push(
        <Tooltip label={'Instagram'} key="artistBanner_instagram">
          <Link
            href={artist.contactInfo.instagramLink}
            isExternal={true}
            fontSize='lg'
            _hover={{ color: "blue.500" }}>
            <Icon as={FaInstagram} />
          </Link>
        </Tooltip>
      );
    }

    if (artist.contactInfo.representativeGallery) {
      icons.push(
        <Tooltip label={'Representation'}  key="artistBanner_gallery">
          <Link
            href={artist.contactInfo.representativeGallery}
            isExternal={true}
            fontSize='lg'
            _hover={{ color: "blue.500" }}>
            <Icon as={RiGalleryLine} />
          </Link>
        </Tooltip>
      );
    }

    if (artist.contactInfo.website) {
      icons.push(
        <Tooltip label={'Website'}  key="artistBanner_website">
          <Link
            href={artist.contactInfo.website}
            isExternal={true}
            fontSize='lg'
            _hover={{ color: "blue.500" }}>
            <Icon as={CgWebsite} />
          </Link>
        </Tooltip>
      );
    }

    return (
      <Flex alignItems="center" justifyContent={{'base': 'center', 'md': 'right'}} direction={'row'} gap={'8px'}>
        {icons}
      </Flex>
    );
  }

  function renderCountryAndLinks() {
    return (
      <Flex direction={{'base': 'column', 'md': 'row'}}>
        {renderArtistCountry()}
        <Spacer />
        {renderArtistContactIcons()}
      </Flex>
    );
  }

  function renderArtistInfo() {
    const containerDivider = <StackDivider borderColor={'gray.100'} />;
    return (
      <Stack
        paddingTop={6}
        spacing={4}
        paddingLeft={{base: 4, md: 0}}
        divider={containerDivider}>
        {!!artist.country.code && <Feature
          icon={
            <Text fontSize="2xl">
              {getUnicodeFlagIcon(artist.country.code)}
            </Text>
          }
          iconBg={'grey.100'}
          text={artist.country.name}
        />}
        <Feature
          icon={<Icon as={BsCalendar3} color={'green.500'} w={5} h={5} />}
          iconBg={'green.100'}
          text={`${artist.birthYear}`}
        />
        <Feature
          icon={
            <Icon as={FiExternalLink} color={'blue.500'} w={5} h={5} />
          }
          iconBg={'blue.100'}
          text={renderArtistContactIcons()}
        />
      </Stack>
    );
  }

  return (
    <Box borderBottomWidth='1px' borderRadius='lg' paddingBottom="20px">
      <Flex direction={{'base': 'column', 'md': 'row'}}>
        <Image loading='eager' fit={'contain'} borderRadius={{'base': '0px', 'md': '16px'}} src={artist.image} alt={artist.name} minWidth={{'base': '100%', 'md': '50%'}} maxHeight="480px" />
        <Box textAlign={{'base': 'center', 'md': 'left'}} paddingLeft={{'base': '0px', 'md': '48px'}} paddingTop={{'base': '20px', 'md': '0px'}}>
          <Flex direction={{'base': 'column', 'md': 'row'}} alignItems="center">
            <Flex direction={'column'}>
              <Heading>
                {artist.name}
              </Heading>
            </Flex>
            <Spacer />
            {renderBannerButton()}
          </Flex>
          {renderArtistInfo()}
          <Flex marginTop='20px'>
            <CollapsableContent text={(
              <Text fontSize='sm' padding={{base: '0px 20px', md: '0px'}}> {artist.description} </Text>
            )} startingHeight={100} />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}
