import {
    Box,
    Flex,
    Skeleton,
    SkeletonText,
    Spacer,
    useColorModeValue
  } from '@chakra-ui/react';
  
  export interface BasicItemCardSkeletonProps {
    useAvatar?: boolean;
  }
  
  export enum BasicItemCardSizingConfig {
    CARD_HEIGHT = '80px'
  }
  
  export enum BasicItemCardStylingConfig {
    BORDER_COLOR_LIGHT = '#f1f3f7',
    BORDER_COLOR_DARK = '#3f434c'
  }
  
  export default function BasicItemCardSkeleton({ useAvatar = false }: BasicItemCardSkeletonProps) {
  
    function renderSkeletonImage() {
      if (useAvatar) {
        return <Skeleton height="40px" width="40px" borderRadius="full" marginRight={4} />;
      } else {
        return <Skeleton height={BasicItemCardSizingConfig.CARD_HEIGHT} width={BasicItemCardSizingConfig.CARD_HEIGHT} marginRight={4} />;
      }
    }
  
    return (
      <Box
        minHeight={BasicItemCardSizingConfig.CARD_HEIGHT}
        width="100%"
        overflow="hidden"
        padding={2}
        border="1px solid"
        borderColor={useColorModeValue(BasicItemCardStylingConfig.BORDER_COLOR_LIGHT, BasicItemCardStylingConfig.BORDER_COLOR_DARK)}
      >
        <Flex direction="row" alignItems="center" justifyContent="flex-start">
          {renderSkeletonImage()}
          <Flex direction="column" textAlign="left" gap={2}>
            <SkeletonText noOfLines={1} width="120px" />
            <SkeletonText noOfLines={1} width="80px" />
          </Flex>
          <Spacer />
          <Flex direction="column" gap={2} alignItems="flex-end">
            <SkeletonText noOfLines={1} width="60px" />
            <Flex direction="row" alignItems="center" gap={1}>
              <Skeleton height="10px" width="30px" />
              <Skeleton height="20px" width="20px" />
            </Flex>
          </Flex>
        </Flex>
      </Box>
    );
  }
  