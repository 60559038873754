import { Box, SimpleGrid, Flex } from '@chakra-ui/react';
import ItemCardSkeleton from '../ItemCardSkeleton/ItemCardSkeleton';
import BasicItemCardSkeleton from '../BasicItemCardSkeleton/BasicItemCardSkeleton';

export interface ItemGridSkeletonProps {
  itemsPerRow?: number;
  rows?: number;
  size?: 'sm' | 'md';
  gridDisplay?: boolean;
}

export default function ItemGridSkeleton({ itemsPerRow = 4, rows = 2, size = 'sm', gridDisplay = false }: ItemGridSkeletonProps) {
  const skeletonsCount = itemsPerRow * rows;

  function renderContent() {
    if (gridDisplay) {
      return (
        <Flex direction='column'>
          {Array.from({ length: skeletonsCount }).map((_, index) => (
            <BasicItemCardSkeleton key={index} />
          ))}
        </Flex>
      );
    } else {
      return (
        <SimpleGrid columns={[2, itemsPerRow]} spacing={{ base: "10px", md: "20px" }}>
          {Array.from({ length: skeletonsCount }).map((_, index) => (
            <ItemCardSkeleton key={index} size={size} />
          ))}
        </SimpleGrid>
      );
    }
  }

  return (
    <Box>
      {renderContent()}
    </Box>
  );
}
